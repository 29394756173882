























import Vue from 'vue'
import drawCelestial from './drawCelestial'
import { Animator } from './animate/Animator'
import { Long } from '../../math/types'
import { ICelestial } from '@/math'
import urlParameters from '../../util/urlParameters'
import { IColor, IOptions, Shapes } from '../../posterTypes'
import getEquivalentLocalTime from './getEquivalentLocalTime'
import CanvasPlaceholder from '../loaders/CanvasPlaceholder.vue'

export default Vue.extend({
  name: 'CanvasCelestial',
  components: {
    CanvasPlaceholder,
  },
  props: {
    date: Date,
    lat: Number,
    long: Number,
    logDebugInfo: Boolean,
    theme: Object as () => IColor,
    shape: String as () => Shapes,
    options: Object as () => IOptions,
    isIos: Boolean,
  },
  data: () => ({
    animationRunning: true,
    animator: undefined,
    lastRenderedCelestial: undefined,
    isReady: false,
    locationChanges: 0,
  }),
  computed: {
    canvasWidth(): number {
      if (urlParameters.printMode) {
        // The celestial is 72% of 50cm wide, which is 36cm. At 450 DPI we need that many pixels
        return 6378
      } else {
        return 1024
      }
    },
    printMode(): boolean {
      return urlParameters.printMode
    },
  },
  watch: {
    lat() {
      this.onLocationChanged()
    },
    long() {
      this.onLocationChanged()
    },
    date() {
      this.onLocationChanged()
    },
    theme() {
      this.redrawCelestial()
    },
    shape() {
      this.redrawCelestial()
    },
    options: {
      deep: true,
      handler() {
        this.redrawCelestial()
      },
    },
  },
  methods: {
    async redrawCelestial() {
      const canvas = this.$refs.canvasContext
      if (!canvas) {
        return
      }
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        return
      }
      const width = canvas.width
      this.animationRunning = this.animator.isAnimationRunning()
      if (this.animationRunning) {
        const celestial: ICelestial = await this.animator.next()
        if (celestial) {
          drawCelestial(
            ctx,
            celestial,
            width,
            this.theme,
            this.shape,
            this.options.constellations,
            this.options.milkyway,
            this.options.coordinateGrid,
            this.options.bold && !this.options.lightBackground
          )
          this.lastRenderedCelestial = celestial
          this.setReady()
        }
        window.requestAnimationFrame(() => this.redrawCelestial())
      } else {
        drawCelestial(
          ctx,
          this.animator.last() || this.lastRenderedCelestial,
          width,
          this.theme,
          this.shape,
          this.options.constellations,
          this.options.milkyway,
          this.options.coordinateGrid,
          this.options.bold && !this.options.lightBackground
        )
        this.setReady()
      }
    },
    async onLocationChanged() {
      const locationChangeId = ++this.locationChanges
      const timezonedDate = await getEquivalentLocalTime(
        this.date,
        this.lat,
        this.long
      )
      // The data changed again in the meantime and we would load stale data now
      if (locationChangeId !== this.locationChanges) {
        return
      }
      this.animator.changeLocation({
        lat: this.lat,
        long: this.long,
        date: timezonedDate,
      })
      if (!this.animationRunning) {
        this.redrawCelestial()
      }
    },
    setReady() {
      if (urlParameters.printMode) {
        setTimeout(() => (this.isReady = true), 1000)
        return
      }
      this.isReady = true
    },
  },
  async mounted() {
    const timezonedDate = await getEquivalentLocalTime(
      this.date,
      this.lat,
      this.long
    )
    let initialLong = this.long - 180
    if (initialLong < -180) {
      initialLong += 360
    }
    this.animator = Animator(
      {
        lat: this.lat,
        long: initialLong as Long,
        date: timezonedDate,
      },
      {
        lat: this.lat,
        long: this.long,
        date: timezonedDate,
      }
    )
    const canvas = this.$refs.canvasContext
    if (!canvas) {
      throw new Error('canvas was null on mounted')
    }
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
    ctx.save()
    // ctx.fillStyle = "black";
    // ctx.fillRect(0, 0, canvas.width, canvas.width);
    this.redrawCelestial()
  },
})
