











































































import Vue from 'vue'
import { isSafari, isiOS } from '../../util/isSafari'
import { getMonths, getYears } from '../../util/dateTimeUtil'
import '../../assets/css/inputs.scss'
import { isUSLocale } from '../../util/locale'
import dayjs from 'dayjs'

export default Vue.extend({
  name: 'DatePicker',
  model: {
    prop: 'date',
    event: 'change',
  },
  components: {
    'vue-timepicker': () =>
      import(/* webpackChunkName: "timepicker" */ './SafariTimepicker.vue'),
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    displayTime: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    isSafari: isSafari && !isiOS,
    isUSLocale: isUSLocale(),
  }),
  computed: {
    years(): number[] {
      return getYears()
    },
    months(): string[] {
      return getMonths()
    },
    days(): string[] {
      const days: string[] = []
      for (let day = 1; day <= dayjs(this.date).daysInMonth(); day++) {
        if (day < 10) {
          days.push('0' + day)
        } else {
          days.push('' + day)
        }
      }
      return days
    },
    year: {
      get(): number {
        return dayjs(this.date).year()
      },
      set(newVal: number): void {
        const newDate = dayjs(this.date)
          .set('year', newVal)
          .toDate()
        this.$emit('change', newDate)
      },
    },
    day: {
      get(): string {
        const date = dayjs(this.date).date()
        return date < 10 ? '0' + date : '' + date
      },
      set(newVal: string) {
        const newDate = dayjs(this.date)
          .set('date', +newVal)
          .toDate()
        this.$emit('change', newDate)
      },
    },
    month: {
      get(): number {
        return dayjs(this.date).month()
      },
      set(month: number) {
        const newDate = dayjs(this.date)
          .set('month', +month)
          .toDate()
        this.$emit('change', newDate)
      },
    },
    time(): string {
      return dayjs(this.date).format('HH:mm')
    },
    safariTimePickerTime(): { HH: string; mm: string } {
      return {
        HH: dayjs(this.date).format('HH'),
        mm: dayjs(this.date).format('mm'),
      }
    },
  },
  methods: {
    onTimeChange(e) {
      const timeValue = this.isSafari ? e.displayTime : e.currentTarget.value

      if (!timeValue) {
        return
      }
      if (timeValue.match(/(hh|mm)/)) {
        return
      }
      const hours = timeValue.split(':')[0] || '0'
      const minutes = timeValue.split(':')[1] || '0'
      const newDate = dayjs(this.date)
        .hour(hours)
        .minute(minutes)
        .toDate()
      this.setDisplayTime(true)
      this.$emit('change', newDate)
    },
    setDisplayTime(newVal: boolean) {
      this.$emit('changeDisplayTime', newVal)
    },
  },
})
