import { IStarPoster } from '@/posterTypes'
import { IDisplayProps } from './IDisplayProps'
import { PrintType } from './IPrintInfo'
export interface IDBSettings {
  initialPosterId?: string
  currentPosterId?: string
}

export interface ILoadedEditorData {
  poster: IStarPoster
  print: PrintType
  isPrintMode: boolean
  dbSettings: IDBSettings
  isLoading: false
  translationLoaded: {
    loaded: boolean
  }
  displayProps: IDisplayProps
}

export interface ILoadingEditorData {
  poster: {}
  print: 'poster'
  isPrintMode: boolean
  dbSettings: IDBSettings
  isLoading: true
  translationLoaded: {
    loaded: boolean
  }
  displayProps: IDisplayProps
}

export type IEditorData = ILoadedEditorData | ILoadingEditorData

export enum FrameColor {
  BLACK = 'BLACK',
  WHITE = 'WHITE',
  WOOD = 'WOOD',
}
