






















import Vue from 'vue'
import PosterContainer from './PosterContainer.vue'
import Menu from './Menu.vue'
import PlaceholderMenu from './PlaceholderMenu.vue'
import CartIcon from './modals/CartIcon.vue'
// import ProgressBar from './ProgressBar.vue'
import makeEditorData from '../provider/makeEditorData'
import { IEditorData } from '@/types/basicTypes'

export default Vue.extend({
  name: 'editor',
  data(): IEditorData {
    return makeEditorData()
  },
  computed: {
    isFullscreenOverlayMode(): boolean {
      return this.$route.path.startsWith('/products/detail')
    },
  },
  components: {
    PosterContainer,
    Menu,
    PlaceholderMenu,
    CartIcon,
  },
})
