












import Vue from 'vue'
import { FrameColor } from '../types/basicTypes'

export default Vue.extend({
  name: 'Frame',
  props: {
    frameColor: {
      type: String as () => FrameColor,
      required: true,
    },
  },
})
