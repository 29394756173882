




















import Vue from 'vue'
import PrintPhoto from './PrintPhoto.vue'

import {
  printImages,
  printToVideoPrint,
  sources,
  VideoPrint,
} from '../provider/printImages'
import isUSLocale from '../util/locale'
import { PrintType } from '../types/IPrintInfo'

export default Vue.extend({
  name: 'PrintPhotoModal',
  props: {
    print: {
      type: String,
      required: true,
    },
  },
  computed: {
    videoPrint() {
      return printToVideoPrint(this.print, isUSLocale())
    },
    detailEntries() {
      return printImages[this.videoPrint.replace('_30x40cm', '')]
    },
    possiblePrints(): VideoPrint[] {
      const galleryPrint = isUSLocale() ? 'gallery-us' : 'gallery-print'
      const framedPoster = isUSLocale() ? 'poster-framed-us' : 'poster-framed'
      return ['poster', framedPoster, 'metal', galleryPrint]
    },
    isUSLocale(): boolean {
      return isUSLocale()
    }
  },
  methods: {
    close() {
      // scroll back to top on mobile
      this.$refs.overlay.scrollTop = 0

      this.$router.go(-1)
    },
    printT(key: string) {
      return this.$t(this.print.replace('_30x40cm', '') + key)
    },
    defaultSrc(print: VideoPrint) {
      const defaultRes = this.isMobile ? 480 : 720
      return this.sources(print.replace('_30x40cm', '')).find(s => s.res === defaultRes)?.src
    },
    sources(print: VideoPrint) {
      if (this.isMobile) {
        return sources[print.replace('_30x40cm', '')].mobile
      }
      return sources[print.replace('_30x40cm', '')].desktop
    },
  },
  watch: {
    print(newVal: PrintType, oldVal: PrintType) {
      if (newVal !== oldVal) {
        this.$refs.overlay.scrollTop = 0
      }
    },
  },
  components: {
    PrintPhoto,
  },
})
