






















































import Vue from 'vue'
import { printImages, printToVideoPrint } from '../provider/printImages'
import PrintPhoto from './PrintPhoto.vue'
import isUSLocale from '../util/locale'
import { PrintType } from '../types/IPrintInfo'

export default Vue.extend({
  components: { PrintPhoto },
  name: 'PrintPhotoActionButton',
  props: {
    print: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shaking: false,
    }
  },
  methods: {
    shake() {
      this.shaking = false
      setTimeout(() => {
        this.shaking = true
      }, 500)
      setTimeout(() => {
        this.shaking = false
      }, 3000)
    },
    productPicture(print: Exclude<PrintType, 'digital'>) {
      const v = printToVideoPrint(print, isUSLocale())
      return printImages[v].slice(0, 3)
    },
  },
  computed: {
    link() {
      return `/products/details/${this.print.replace('_30x40cm', '')}`
    },
    hasTranslations() {
      return this.$t('printPhotoModal.button') !== 'printPhotoModal.button'
    },
    isOnPrintScreen() {
      return this.$route.path.startsWith('/products')
    },
  },
  watch: {
    print() {
      this.shake()
    },
  },
  mounted() {
    this.shake()
  },
})
