



















































import Vue from 'vue'
import Poster from './Poster.vue'
import Box from './3dBox.vue'
import Frame from './3dFrame.vue'
import { IStarPoster } from '../posterTypes'
import parameters from '../util/urlParameters'
import { IDisplayProps } from '../types/IDisplayProps'
import { PrintType } from '../types/IPrintInfo'
import isUSLocale from '../util/locale'
import PrintPhotoModal from './PrintPhotoModal.vue'
import PrintPhotoActionButton from './PrintPhotoActionButton.vue'

export default Vue.extend({
  name: 'posterContainer',
  components: {
    Poster,
    Box,
    Frame,
    PrintPhotoModal,
    PrintPhotoActionButton,
  },
  data() {
    return {
      showDigitalModal: false,
      isUSLocale: isUSLocale(),
    }
  },
  props: {
    posterProps: {
      type: Object as () => IStarPoster,
      required: true,
    },
    displayProps: {
      type: Object as () => IDisplayProps,
      required: true,
    },
  },
  computed: {
    displayedPrint(): PrintType | 'poster_30x40cm' | 'poster-framed_30x40cm' {
      return this.displayProps.show3d ? this.displayProps.print : 'poster'
    },
    showMetal(): boolean {
      if (this.displayedPrint === 'metal-print') {
        return true
      }
      return this.displayedPrint === 'gallery-print' && !isUSLocale()
    },
    showFrame(): boolean {
      return this.displayedPrint === 'poster-framed' || this.displayedPrint === 'poster-framed_30x40cm'
    },
    onProductScreen(): boolean {
      return this.$route.path === '/products'
    },
    videoViewPrint(): 'poster' | 'metal-print' | 'gallery-print' | undefined {
      if (!this.$route.path.startsWith('/products/detail')) {
        return undefined
      }
      const print = this.$route.path.split('/').slice(-1)[0]
      return print
    },
  },
})
