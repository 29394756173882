import defaultPoster from './defaultPoster'
import urlParameters from '../util/urlParameters'
import { loadPoster } from './endpoint'
import { translationLoaded } from '@/i18n'
import { mapPosterDataIncoming } from '../util/mapPosterData'
import { readPosterLocalStorage } from '../util/posterLocalStorage'
import { FrameColor, IEditorData } from '@/types/basicTypes'
import { IDisplayProps } from '@/types/IDisplayProps'
import { PrintType } from '@/types/IPrintInfo'

// Attention: The default print must be available across all markets or the 
// Product Picker can/will be broken
export const defaultPrint = PrintType.posterFramed
const defaultDisplayProps: IDisplayProps = {
  print: defaultPrint,
  frameColor: FrameColor.BLACK,
  show3d: false,
}

export default function makeEditorData(): IEditorData {
  if (urlParameters.posterId) {
    const result: IEditorData = {
      poster: {},
      print: 'poster',
      isPrintMode: urlParameters.printMode,
      dbSettings: {
        initialPosterId: urlParameters.posterId,
      },
      isLoading: true,
      translationLoaded,
      displayProps: defaultDisplayProps,
    }
    if (urlParameters.posterId !== 'undefined') {
      localStorage.setItem('currentPosterId', urlParameters.posterId)
    }
    loadPoster(urlParameters.posterId).then(async poster => {
      const mappedPoster = await mapPosterDataIncoming(poster)
      Object.assign(result, {
        poster: mappedPoster,
      })
      Object.assign(result, {
        isLoading: false,
      })
    })
    return result
  } else if (
    localStorage.getItem('savedPoster') &&
    !urlParameters.posterId &&
    !urlParameters.printMode
  ) {
    return {
      poster: readPosterLocalStorage(),
      print: PrintType.poster,
      isPrintMode: urlParameters.printMode,
      dbSettings: {
        currentPosterId: undefined,
        initialPosterId: undefined,
      },
      isLoading: false,
      translationLoaded,
      displayProps: defaultDisplayProps,
    }
  } else {
    return {
      poster: defaultPoster(),
      print: PrintType.poster,
      isPrintMode: urlParameters.printMode,
      dbSettings: {
        currentPosterId: undefined,
        initialPosterId: undefined,
      },
      isLoading: false,
      translationLoaded,
      displayProps: defaultDisplayProps,
    }
  }
}
