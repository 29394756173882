
















































































import Vue from 'vue'
import { IDBSettings } from '@/types/basicTypes'
import { IStarPoster } from '@/posterTypes'

import { savePoster } from '@/provider/endpoint'
import { writePosterLocalStorage } from '@/util/posterLocalStorage'
import onRouteChanged from '../util/onRouteChanged'
import Location from './inputs/Location.vue'
import DatePicker from './inputs/DatePicker.vue'
import AdvancedOptions from './inputs/AdvancedOptions.vue'
import Dedication from './inputs/Dedication.vue'
import Theme from './inputs/Theme.vue'
import Options from './inputs/Options.vue'
import PrintSelect from './inputs/PrintSelect.vue'
import { IDisplayProps } from '@/types/IDisplayProps'
import { formatDate } from '../util/dateTimeUtil'
import { language } from '../util/locale'
// eslint-disable-next-line
import isMobile from '../util/isMobile'

export default Vue.extend({
  name: 'Menu',
  data: () => ({
    hasError: 'none',
  }),
  components: {
    Location,
    DatePicker,
    AdvancedOptions,
    Dedication,
    Theme,
    Options,
    PrintSelect,
  },
  props: {
    posterProps: {
      type: Object as () => IStarPoster,
      required: true,
    },
    dbSettings: {
      type: Object as () => IDBSettings,
      required: true,
    },
    displayProps: {
      type: Object as () => IDisplayProps,
      required: true,
    },
  },
  methods: {
    computeFormattedDate() {
      this.posterProps.dateFormatted = formatDate(
        this.posterProps.date,
        this.posterProps.displayTime
      )
    },
    updateDisplayTime(newValue: boolean) {
      this.posterProps.displayTime = newValue
    },
    shouldShowRoute(page: string, productSelect = false): boolean | undefined {
      if (!this.isMobile) {
        if (!productSelect && this.$route.path === '/') {
          return true
        }
        if (productSelect && this.$route.path === '/') {
          return false
        }
        if (productSelect && this.$route.path.startsWith(page)) {
          return true
        }
        // Bugfix for new editor getting old editor routes.
        if (productSelect && this.$route.path === '/confirm') {
          return true
        }
      }
      // Bugfix for new editor getting old editor routes.
      if (this.isMobile && this.$route.path === '/confirm') {
        return true
      }
      if (this.isMobile) {
        if (page === '/') {
          return this.$route.path === '/'
        }
        return this.$route.path.startsWith(page)
      }
    },
    nextPage(): string | null {
      const routesWithoutNextPage = ['digital', 'frame-select', 'products']
      const route = this.$route.path.replace('/', '')
      const nextRoutesMobile = {
        '': 'layout',
        layout: 'options',
        options: 'dedication',
        dedication: 'products',
      }
      const nextRoutesDesktop = {
        '': 'products',
      }

      const nextRoutes = this.isMobile ? nextRoutesMobile : nextRoutesDesktop
      const nextRoute = nextRoutes[route]

      if (!nextRoute) {
        if (routesWithoutNextPage.find(r => route.startsWith(r))) {
          return null
        }
        throw new Error('Unknown route: ' + this.$route.path)
      }
      return '/' + nextRoute
    },
    async savePoster(): Promise<void> {
      if (this.nextPage() === '/products') {
        this.$set(this.dbSettings, 'currentPosterId', undefined)
        const posterId = await savePoster(this.posterProps)
        this.$set(this.dbSettings, 'currentPosterId', posterId)
        this.$set(this, 'dbSettings', this.dbSettings)
        localStorage.setItem('currentPosterId', posterId)
      }
    },
    onError(displayError: 'silent' | 'visible' | 'none') {
      this.hasError = displayError
    },
  },
  computed: {
    showPrevBtn(): boolean {
      return this.$route.path != '/frame-select'
    },
    useMaxWidth() {
      // bug with textarea width prevents it's container from expanding.
      return this.$route.path === '/dedication' && this.isMobile
        ? 'width: 100vw;'
        : null
    },
    logoSrc() {
      if (!this.isMobile) {
        return language === 'de'
          ? require('../assets/img/logo-nachthimmel.svg')
          : require('../assets/img/logo-thestarposter.svg')
      } else {
        return require('../assets/img/logo-small.svg')
      }
    },
    showLogo() {
      if (this.isMobile) {
        return false
      }
      return true
    },
    rootURL() {
      if (window.location.pathname.includes('editor')) {
        return '/'
      } else {
        return 'https://nachthimmel.de'
      }
    },
  },
  watch: {
    posterProps: {
      deep: true,
      handler() {
        writePosterLocalStorage(this.posterProps)
      },
    },
    $route: {
      handler(newLocation) {
        onRouteChanged(newLocation.path || '/')
      },
      immediate: true,
    },
    'posterProps.date'() {
      this.computeFormattedDate()
    },
    'posterProps.displayTime'() {
      this.computeFormattedDate()
    },
  },
})
