<template>
  <div id="placeholder">
    <svg
      viewBox="11.268 1.151 218.536 219.986"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="111.567"
        cy="97.839"
        r="35.204"
        :fill="color"
        transform="matrix(3.094568, 0, 0, 3.11578, -224.661957, -193.692215)"
      />
      <g transform="matrix(0.521695, 0, 0, 0.521695, 58.53899, 52.949463)">
        <g>
          <path
            d="M109.3 138.8a2.6 2.6 0 00-2.6-2.6 2.7 2.7 0 00-1.9.8 2.6 2.6 0 00-.7 1.9 2.6 2.6 0 002.6 2.6 2.7 2.7 0 002.6-2.7zM192.5 136.2a3.9 3.9 0 00-3.7 3.9 3.8 3.8 0 003.7 3.8 3.8 3.8 0 003.9-3.8 3.9 3.9 0 00-3.9-3.9zM135.4 79.5a3.8 3.8 0 103.8-3.8 3.8 3.8 0 00-3.8 3.8z"
            style="fill: rgb(255, 255, 255);"
          />
          <circle
            cx="89.6"
            cy="3.4"
            r="2.3"
            style="fill: rgb(255, 255, 255);"
          />
          <path
            d="M176.5 72.7a2.1 2.1 0 002.1-2.1 2 2 0 00-2.1-2 2 2 0 00-2 2 2 2 0 002 2.1zM45.9 83.3l16.5-.7 7.1 14.9-.6-16.5 14.8-7.1-16.5.7-7-14.9.6 16.5-14.9 7.1zM137.2 37.1l15 12.9-8.7-17.8 13-15-17.8 8.8-15-13 8.6 17.8-12.8 15 17.7-8.7zM201.8 58.9l2.6-13 13.1-2.7-13.1-2.6-2.6-13.1-2.7 13.1-13.1 2.6 13.1 2.7 2.7 13zM218.9 124.5l-8.8-10.4 6-12.2-10.4 8.8-12.2-6.1 8.8 10.5-6.1 12.2 10.5-8.8 12.2 6zM108.8 100l18.2 11.9a2.4 2.4 0 001.2.3h.1a2.4 2.4 0 001.7-.6 3 3 0 00.8-1.7 2.4 2.4 0 00-1.2-2.1l-18.1-11.9a2.6 2.6 0 00-1.9-.4 2.8 2.8 0 00-1.5 1.1 2.6 2.6 0 00-.4 1.9 3.3 3.3 0 001.1 1.5z"
            style="fill: rgb(255, 255, 255);"
          />
          <circle
            cx="105.1"
            cy="94.6"
            r="2.4"
            style="fill: rgb(255, 255, 255);"
          />
          <path
            d="M236.2 141.5a116.5 116.5 0 01-63.4 29.7l-22.9-37.7a6.5 6.5 0 001.8-4.6 5.7 5.7 0 00-.1-1.3l6.2 2.2 1.3.2a3.4 3.4 0 003.1-2.3l2.6-7.3a3.5 3.5 0 00-2-4.3l-22.9-8.2-34.4-23.6a4 4 0 00-5.2.8c-2.3 2.8-10 13.1-11.2 30.1a3.7 3.7 0 00.9 2.8 4.6 4.6 0 002.8 1.3l40.4 1.8 7.3 2.7a6.5 6.5 0 00-2.3 5.1 7.1 7.1 0 001.4 4.2l-18.3 33.6C59.9 147 25.8 81.1 45.3 19.6a125.5 125.5 0 017.3-17.8l.9-1.8-1.6 1.2c-55.1 38.6-68.5 114.9-29.8 170a122.1 122.1 0 00170.1 29.7A120.8 120.8 0 00237 142l.7-1.9zm-101.2-31l-2 5.7-39-1.8a48.7 48.7 0 019.5-25.5zm-87.1 81.3a2.6 2.6 0 001-2 2.7 2.7 0 00-2.7-2.7 2.6 2.6 0 00-2.3 1.3l-2.2-2 2.3-3.5a2.5 2.5 0 00-4.1-2.7v.2l-1.7 2.6-3-3.1 4.4-6.9a2.3 2.3 0 00-.7-3.2h-.1a2.5 2.5 0 00-3.4.7l-3.5 5.5-3-3.7 6.7-10.7a2.5 2.5 0 00.4-1.7 2.4 2.4 0 00-1-1.5h-.1a2.5 2.5 0 00-3.4.7l-5.6 9.1c-.9-1.3-1.8-2.5-2.6-3.8l8.5-13.5a2.7 2.7 0 00-.8-3.4 2.5 2.5 0 00-3.4.8l-7.1 11.5-2.6-4.6 9.7-15.4a2.5 2.5 0 00-.8-3.4h-.1a2.3 2.3 0 00-3.2.8l-8 12.9c-.8-1.7-1.5-3.4-2.2-5l10.8-17.4a2.5 2.5 0 00-.7-3.4 2.5 2.5 0 00-3.3.9l-8.9 14.1A116.8 116.8 0 0141.3 16.1a117.6 117.6 0 00-6.3 39 122.1 122.1 0 00120.7 122v21.6a2.4 2.4 0 004.8 0v-21.6l3.9-.2v19.7a2.5 2.5 0 104.9 0v-20.1l3.3-.4h1.1v17.3a2.5 2.5 0 004.9 0v-18.2l4-.8v13a2.4 2.4 0 002.4 2.4 2.4 2.4 0 002.4-2.4v-14.1l4.5-1.3v8a2.5 2.5 0 004.9 0v-9.5l3.9-1.5v4.2a2.5 2.5 0 004.9 0v-6.1l3.5-1.6a2.1 2.1 0 00-.4 1.2 2.3 2.3 0 00.6 1.6 2.2 2.2 0 001.5.8h.3a2.3 2.3 0 002.2-2.4 2.4 2.4 0 00-2-2.3 112 112 0 0014.2-8.3 116.4 116.4 0 01-35.2 40.2 117.6 117.6 0 01-142.4-4.5zm99.6-52.8l20 32.8c-3.5.3-7 .5-10.5.5l-7.4-.2zm-4.4-10.1a1.9 1.9 0 011.9-1.9 2 2 0 011.9 1.9 1.9 1.9 0 01-1.9 1.9 1.8 1.8 0 01-1.9-1.9zm-5.4-11.4l1.6-4.6 7.9 2.8-1.7 4.6zm7 54.1a133.5 133.5 0 01-18.7-3.4l16.6-30.3zm7.3-49.1l1.7-4.5 6.1 2.1-1.7 4.6z"
            style="fill: rgb(255, 255, 255);"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'CanvasPlaceholder',
  props: {
    color: String,
  },
})
</script>
<style scoped lang="css">
#placeholder {
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
