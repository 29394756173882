import { PrintType } from '@/types/IPrintInfo'
import { cartUrl } from './endpointConfig'

interface IAddToCartParams {
  'lineItemProperties[delivery]': PrintType | "poster-framed_30x40cm"
  productId: string
  productType: 'starmap'
  replaceId?: string
  frameColor?: string
}

export default function getAddToCartUrl(
  print: PrintType | "poster-framed_30x40cm",
  posterId: string,
  replaceId?: string,
  frameColor?: string
): string | undefined {
  if (!posterId) {
    return undefined
  }
  const params: IAddToCartParams = {
    'lineItemProperties[delivery]': print,
    productId: posterId,
    productType: 'starmap',
    replaceId,
  }
  if (params.replaceId === params.productId || !params.replaceId) {
    delete params.replaceId
  }
  if (print === PrintType.posterFramed || print === "poster-framed_30x40cm") {
    params.frameColor = frameColor
  }
  return (
    cartUrl +
    '?' +
    Object.keys(params)
      .filter(p => params[p])
      .map(p => p + '=' + params[p])
      .join('&')
  )
}
