import { IPrintInfo } from '@/types/IPrintInfo'
import ajax from '@/util/ajax'
import { backendUrl } from '@/util/endpointConfig'
import { language } from '@/util/locale'

export default async function getAvailablePrints(): Promise<IPrintInfo[]> {
  const url = backendUrl + `print/starmap-legacy/guess/${language}`
  const result = await ajax<IPrintInfo[]>('GET', url)
  return result.ans.sort(
    (p1, p2) => (getEurPriceIncludingShippingFee(p1) - getEurPriceIncludingShippingFee(p2))
  )
}

function getEurPriceIncludingShippingFee(print: IPrintInfo): number {
  if (!print.isPhysical) {
    return print.prices.EUR
  }
  const { prices, shippingFee } = print
  return prices.EUR + shippingFee.EUR
}