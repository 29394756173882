import { getEnvironment } from './getEnvironment'

const environment = getEnvironment()

function getCartUrl(): string {
  if (
    window.location.host === 'editor.nachthimmel.de' ||
    window.location.host === 'thestarposter.netlify.app'
  ) {
    return 'https://cart.nachthimmel.de/cart/'
  }
  return '../cart/'
}
function getAPIEndpoint(): string {
  if (environment === 'local') {
    // replace this with dev backend as needed
    return 'https://backend.dev.heurekaprints.de/'
  } else if (window.location.host.match(/.com|.it|.nl|.de|.fr|.es/g)) {
    return '/backend/'
  } else if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('io') ||
    window.location.host.includes('netlify') ||
    window.location.host.includes('808')
  ) {
    return 'https://backend.dev.heurekaprints.de/'
  }
  return '/backend/' //'https://backend.heurekaprints.de/'
}
const backendUrl = getAPIEndpoint()
const cartUrl = getCartUrl()
const endpoint = 'starmap-legacy'

export { backendUrl, endpoint, cartUrl }
