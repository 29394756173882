































import Vue from 'vue'
import '../../assets/css/inputs.scss'
import {
  getDebouncedAutocompleteResults,
  IAutocompleteResult,
} from '@/provider/autocompleteProvider'
import geolocation from '../../provider/geolocationProvider'
import { ILocation } from '../../posterTypes'

export default Vue.extend({
  name: 'Location',
  model: {
    prop: 'location',
    event: 'change',
  },
  props: {
    location: {
      type: Object as () => ILocation,
      required: true,
    },
  },
  data: () => ({
    currentSearchTerm: undefined,
    searchResults: [],
    isSearching: false,
    isFocused: false,
    inputCleared: false,
    autocompleteSessionToken: undefined,
  }),
  computed: {
    inputVal: function() {
      return this.currentSearchTerm !== undefined
        ? this.currentSearchTerm
        : this.location.locationName
    },
  },
  methods: {
    async onLocationSelected(result: IAutocompleteResult): Promise<void> {
      this.isSearching = false
      this.isFocused = false
      this.$refs.locationInput.style = 'border: none'
      this.$refs.label.style = 'color: initial'
      this.$emit('error', 'none')
      this.currentSearchTerm = undefined
      this.searchResults = []

      const locationResult = await geolocation({
        placeId: result.place_id,
        query: result.structured_formatting.main_text,
        sessionToken: this.autocompleteSessionToken,
      })
      this.autocompleteSessionToken = this.generateSessionToken()

      this.$emit('change', {
        locationName: result.structured_formatting.main_text,
        lat: locationResult.lat,
        long: locationResult.lon,
      })
    },
    async onKeyChange(event): Promise<void> {
      this.currentSearchTerm = event.target.value
      if (this.currentSearchTerm !== '') {
        this.isSearching = true
        this.$emit('error', 'silent')
        const result = await getDebouncedAutocompleteResults({
          query: event.target.value,
          sessionToken: this.autocompleteSessionToken,
        })
        // The user might have continued typing so that this request went stale, if yes we can discard it
        if (event.target.value !== this.currentSearchTerm) {
          return
        }
        if (this.isMobile) {
          this.searchResults = result.slice(0, 3)
        } else {
          this.searchResults = result
        }
      }
    },
    handleClick() {
      if (!this.inputCleared) {
        this.$refs.locationInput.value = ''
        this.inputCleared = true
      }
    },
    onInputBlur() {
      // 100ms delay on this check as to prevent a short flash of red box outline.
      setTimeout(() => {
        if (this.isSearching) {
          this.$refs.locationInput.style = 'border: solid 1px red'
          this.$refs.label.style = 'color: #ff4d4d'
          this.$emit('error', 'visible')
        }
        this.isFocused = false
      }, 100)
    },
    generateSessionToken(): string {
      return Math.random()
        .toString(20)
        .substring(2, 8)
    },
  },
  created() {
    this.autocompleteSessionToken = this.generateSessionToken()
  },
})
