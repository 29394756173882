








import Vue from 'vue'
import LoadingSpinner from './loaders/LoadingSpinner.vue'

export default Vue.extend({
  name: 'PlaceholderMenu',
  components: {
    LoadingSpinner,
  },
})
