





























































































































import Vue from 'vue'
import CanvasCelestial from './celestial/CanvasCelestial.vue'
import { isSafari, isiOS } from '../util/isSafari'
import { IStarPoster } from '../posterTypes'
import { formatPair } from '@mapbox/sexagesimal'
import parameters from '../util/urlParameters'
import isUSLocale from '../util/locale'
import { shortenLines, reduceLineNumbers } from '../util/inputTools'

export default Vue.extend({
  name: 'Poster',
  components: {
    CanvasCelestial,
  },
  props: {
    posterProps: {
      type: Object as () => IStarPoster,
      required: true,
    },
  },
  data() {
    return {
      logDebugInfo: false,
      isSafari: isSafari || isiOS,
    }
  },
  computed: {
    viewBox(): string {
      return [
        0,
        0,
        this.SVGStyle.posterDimensions.posterWidth,
        this.SVGStyle.posterDimensions.posterHeight,
      ].join(' ')
    },
    usaRatio() {
      if (parameters.printMode) {
        return parameters.ratio === '43'
      }
      return isUSLocale()
    },
    posterDimensionsLabel() {
      if (this.usaRatio) {
        return '18x24 inches'
      } else {
        return '50x70cm'
      }
    },
    SVGStyle(): {} {
      return {
        posterDimensions: {
          posterWidth: this.usaRatio ? 525 : 500,
          posterHeight: 700,
        },
        celestialContainer: {
          width: this.usaRatio ? 395 : 360,
          height: this.usaRatio ? 395 : 365,
          y: this.usaRatio ? 45 : 60,
          x: this.usaRatio ? 65 : 70,
        },
        dedicationPos: {
          y: this.usaRatio ? '66.65%' : '65.65%',
        },
        locationPos: {
          y: this.usaRatio ? '85%' : '82%',
        },
        coordsPos: {
          y: this.usaRatio ? '87.8%' : '85%',
        },
        datePos: {
          y: this.usaRatio ? '90.8%' : '87.8%',
        },
        backgroundColor: {
          backgroundColor: this.posterProps.options.lightBackground
            ? this.posterProps.style.backgroundColor
            : this.posterProps.style.mapColor,
        },
        textColor: {
          color: this.posterProps.options.lightBackground
            ? this.posterProps.style.textColor
            : this.posterProps.style.starColor,
        },
        fillColor: this.posterProps.options.lightBackground
          ? this.posterProps.style.textColor
          : this.posterProps.style.starColor,
      }
    },
    errorCheckedDedication(): string {
      return reduceLineNumbers(shortenLines(this.posterProps.dedication)).join(
        '\r\n'
      )
    },
    formattedCoordinates(): string {
      return formatPair({
        lat: this.posterProps.location.lat,
        lon: this.posterProps.location.long,
      })
    },
    frameDimensions(): { x: number; y: number; width: number; height: number } {
      const offset = this.SVGStyle.posterDimensions.posterWidth * 0.03
      return {
        x: offset,
        y: offset,
        width: this.SVGStyle.posterDimensions.posterWidth - offset * 2,
        height: this.SVGStyle.posterDimensions.posterHeight - offset * 2,
      }
    },
  },
  methods: {
    //
  },
})
