import ajax from '../util/ajax'
import { backendUrl } from '../util/endpointConfig'
import * as Sentry from '@sentry/browser'

const maxRequestFrequency = 200

let autocompleteTimeout = -1

export interface IAutocompleteResult {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
  }
  place_id: string
}

export async function getDebouncedAutocompleteResults({
  query,
  sessionToken,
}: {
  query: string
  sessionToken: string
}): Promise<IAutocompleteResult[]> {
  const autocompleteResults = await fetchDebouncedAutocompleteResults({
    query,
    sessionToken,
  })
  return handleAutocompleteResults(autocompleteResults, query)
}

const fetchDebouncedAutocompleteResults = async ({
  query,
  sessionToken,
}: {
  query: string
  sessionToken: string
}): Promise<IAutocompleteResult[]> => {
  clearTimeout(autocompleteTimeout)

  await new Promise(res => {
    autocompleteTimeout = setTimeout(res, maxRequestFrequency)
  })

  return await fetchAutocompleteResults({ query, sessionToken })
}

const fetchAutocompleteResults = async ({
  query,
  sessionToken,
}: {
  query: string
  sessionToken: string
}): Promise<IAutocompleteResult[]> => {
  const { ans } = await ajax<IAutocompleteResult[]>(
    'GET',
    `${backendUrl}services/autocomplete/${encodeURIComponent(
      query
    )}?sessionToken=${sessionToken}`
  )

  return ans
}

const handleAutocompleteResults = (
  results: IAutocompleteResult[],
  query: string
): IAutocompleteResult[] => {
  if (!Array.isArray(results)) {
    throw new Error('Location answer is not an array: ' + results)
  }
  const isResponseUnstructured = results.find(
    ({ structured_formatting }) => !structured_formatting
  )

  if (isResponseUnstructured) {
    try {
      throw new Error('Structured Formatting is undefined for ' + query)
    } catch (e) {
      Sentry.addBreadcrumb({
        message: JSON.stringify(results),
      })
      Sentry.captureException(e)
      return results.filter(answer => answer.structured_formatting)
    }
  }

  return results
}
