/* eslint-disable @typescript-eslint/interface-name-prefix */
declare global {
  interface Window {
    dataLayer?: any[]
  }
}

export default function onRouteChanged(newRoute: string) {
  const dataLayer = window.dataLayer;
  if (!dataLayer) {
    return;
  }
  const page_location = location.origin + (location.pathname + newRoute).replace('//', '/');
  dataLayer.push({
    event: 'page_view',
    page_location: page_location,
    page_title: document.title,
  });
}
