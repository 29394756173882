import { HEART_SCALING_FACTOR } from './getOutOfScreenShadowOffsetX'
import { heartPath } from './heartPath'

const drawHeart = (ctx, width) => {
  ctx.beginPath()
  ctx.scale((3.96 / 1024) * width, (4.1 / 1024) * width)
  ctx.translate(1, 6)
  ctx.clip(heartPath)
  ctx.setTransform(1, 0, 0, 1, 0, 0)

  // we scale everything that we draw afterwards a bit bigger
  ctx.translate(width / 2, width / 2)
  ctx.scale(HEART_SCALING_FACTOR, HEART_SCALING_FACTOR)
  ctx.translate(-width / 2, -width / 2)
}

export default drawHeart
