







































































import Vue from 'vue'
import { IDBSettings } from '@/types/basicTypes'
import { language } from '@/util/locale'
import { resetUnsavedPoster } from '@/util/posterLocalStorage'
import getAddToCartUrl from '../../util/getAddToCartUrl'
import isUSLocale from '../../util/locale'
import { FrameColor } from '../../types/basicTypes'
import PrintFrameSelect from './PrintFrameSelect.vue'
// eslint-disable-next-line
import isMobile from '../../util/isMobile'

export default Vue.extend({
  name: 'PrintItem',
  model: {
    prop: 'frameColor',
    event: 'change',
  },
  props: {
    print: {
      type: Object,
      required: true,
    },
    dbSettings: {
      type: Object as () => IDBSettings,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    frameColor: {
      type: String as () => FrameColor,
      required: false,
    },
  },
  data() {
    return {
      showReadMoreButton: false,
    }
  },
  mounted() {
    this.showReadMoreButton =
      this.$refs.mainWrapper?.scrollHeight >
      this.$refs.mainWrapper?.clientHeight
  },
  computed: {
    isFeatured(): boolean {
      return this.print.id === 'metal-print'
    },
    price() {
      if (!this.print.prices[this.currency]) {
        throw new Error(`No price for ${this.print.id} in ${this.currency}`)
      }
      return this.formatCurrency(this.print.prices[this.currency])
    },
    frameColors() {
      return this.print.availableColors
    },
    shipping(): { text: string; isFree: boolean } | undefined {
      if (!this.print.isPhysical) {
        return
      }
      if (this.print.shippingFee[this.currency] > 0) {
        return {
          text: this.$t('printSelect.shipping.exact', {
            price: this.formatCurrency(
              this.print.shippingFee[this.currency]
            ),
          }),
          isFree: false,
        }
      }
      if ((this.print.shippingFee[this.currency] ?? this.print.shippingFee['EUR']) === 0) {
        return {
          text: this.$t('printSelect.shipping.free'),
          isFree: true,
        }
      }
      return {
        text: this.$t('printSelect.shipping.generic'),
        isFree: false,
      }
    },
    dimensions(): string | undefined {
      if (this.print.id === 'poster_30x40cm' || this.print.id === 'poster-framed_30x40cm') {
        return isUSLocale() ? '12x16 in' : '30 x 40 cm'
      }
      if (this.print.isPhysical) {
        return isUSLocale() ? '18x24 in' : this.$t('printSelect.dimensions')
      }
      return undefined
    },
    isEditMode() {
      return !!this.dbSettings.replaceId
    },
    descriptionPoints(): string[] {
      return this.printT('Description')
        .split('\n')
        .filter(line => line.length)
    },
    deliveryTimeMessage(): string {
      if (!this.print.isPhysical) {
        return this.$t('digitalPosterImmediatelyAvailable')
      }
      return this.print.deliverTimeFormatted
    },
    deliveryMessageStyle() {
      switch (this.print.highlighting) {
        case 'GOOD':
          return 'delivery-good'
        case 'WARN':
          return 'delivery-warn'
        case 'BAD':
          return 'delivery-bad'
        case 'NONE':
        default:
          return null
      }
    },
    comparePrice(): string | undefined {
      if (!this.print.comparePrices[this.currency]) {
        return undefined
      }
      return this.formatCurrency(this.print.comparePrices[this.currency])
    },
    discountAmount(): number | undefined {
      if (!this.print.comparePrices[this.currency]) {
        return undefined
      }
      return Math.round(
        100 -
          (this.print.prices[this.currency] /
            this.print.comparePrices[this.currency]) *
            100
      )
    },
  },
  methods: {
    printT(key: string) {
      if (isUSLocale()) {
        const fullKey = this.print.id + key + 'US'
        if (this.$t(fullKey) !== fullKey) {
          return this.$t(fullKey)
        }
      }
      return this.$t(this.print.id + key)
    },
    formatCurrency(val: number): string {
      return new Intl.NumberFormat(language, {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: val === Math.round(val) ? 0 : 2,
      }).format(val)
    },
    addToCart(e): void {
      e.stopPropagation()
      const url = getAddToCartUrl(
        this.print.id,
        this.dbSettings.currentPosterId ||
          localStorage.getItem('currentPosterId'),
        this.dbSettings.initialPosterId,
        this.frameColor
      )
      if (url) {
        const posterId =
          this.dbSettings.currentPosterId ||
          localStorage.getItem('currentPosterId')
        this.$router.replace('?posterId=' + posterId)
        resetUnsavedPoster()
        location.href = url
      } else {
        throw new Error('No URL available')
      }
    },
    expand(): void {
      if (!this.showReadMoreButton) {
        return
      }
      this.$emit('toggleExpand', this.print.id)
    },
  },
  components: { PrintFrameSelect },
  watch: {
    $route: {
      handler() {
        // this only works if the component is visible
        // we dont have an event to check if the component becomes visible
        // because we use v-show not v-if
        // therefore we check whenever the route changes
        this.showReadMoreButton =
          this.$refs.mainWrapper?.scrollHeight >
          this.$refs.mainWrapper?.clientHeight
      },
    },
  },
})
