export enum PrintType {
  poster = 'poster',
  posterFramed = 'poster-framed',
  digital = 'digital',
  metalPrint = 'metal-print',
  galleryPrint = 'gallery-print',
}

export type CountryCode = string
export type Currency = string
export interface IDeliveryTimeResult {
  min: number
  max: number
  madeIn: CountryCode | CountryCode[]
  deliverTimeFormatted: string
  madeInFormatted: string
}

interface IBasicPrintInfo {
  id: PrintType
  prices: Record<Currency, number>
  isPhysical: boolean
}

interface IPhysicalPrintInfo extends IDeliveryTimeResult, IBasicPrintInfo {
  isPhysical: true
  shippingFee: Record<
    | 'EUR'
    | 'CHF'
    | 'GBP'
    | 'USD',
    number
  >;
}

interface IDigitalPrintInfo extends IBasicPrintInfo {
  id: PrintType.digital
  isPhysical: false
}

export type IPrintInfo = IPhysicalPrintInfo | IDigitalPrintInfo
