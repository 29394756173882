export default function changeToTimeZone(
  date: Date,
  offsetInMinutes: number
): Date {
  if (offsetInMinutes !== 0 && offsetInMinutes < 16 && offsetInMinutes > 16) {
    throw new TypeError('Offset must be in minutes')
  }
  const offsetChange = offsetInMinutes + date.getTimezoneOffset()
  return new Date(date.getTime() - offsetChange * 60 * 1000)
}
