import {
  Currency,
  getCurrencyFromCookie,
  isCurrency,
  storeCurrencyInCookie,
} from '@/util/preferredCurrency'
import { backendUrl } from '@/util/endpointConfig'
import parameters from '@/util/urlParameters'
import * as Sentry from '@sentry/browser'

async function getCurrencyFromBackend(): Promise<Currency | undefined> {
  const url = backendUrl + 'locale'
  const response = await fetch(url, {
    method: 'GET',
    redirect: 'follow',
  })
  const data = await response.json()
  const { currency } = data
  if (!currency) {
    return undefined
  }
  if (!isCurrency(currency)) {
    throw new Error(
      `Invalid currency returned from backend, returned: ${currency}`
    )
  }
  return currency
}

async function getCurrency(): Promise<Currency | undefined> {
  const cookieCurrency = getCurrencyFromCookie()
  if (cookieCurrency) {
    Sentry.addBreadcrumb({
      category: 'DEBUG',
      message: 'currency from cookieCurrency: ' + cookieCurrency,
      level: Sentry.Severity.Info,
    })
    return cookieCurrency
  }

  // if we are in print mode, the currency doesnt matter
  if (parameters.printMode) {
    const defaultCurrency = Currency.EUR
    Sentry.addBreadcrumb({
      category: 'DEBUG',
      message: 'default currency in print mode: ' + defaultCurrency,
      level: Sentry.Severity.Info,
    })
    return defaultCurrency
  }

  const backendCurrency = await getCurrencyFromBackend()
  if (backendCurrency) {
    storeCurrencyInCookie(backendCurrency)
  }
  Sentry.addBreadcrumb({
    category: 'DEBUG',
    message: 'currency from backend: ' + backendCurrency,
    level: Sentry.Severity.Info,
  })
  return backendCurrency
}

export { getCurrency }
