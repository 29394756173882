





























import Vue from 'vue'
import '../../assets/css/inputs.scss'
import { IOptions } from '../../posterTypes'
import Checkbox from './atomics/Checkbox.vue'

export default Vue.extend({
  name: 'Options',
  components: { Checkbox },
  props: {
    options: {
      type: Object as () => IOptions,
      required: true,
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    //
  },
  methods: {
    onUseLightBackgroundToggle() {
      this.options.lightBackground = !this.options.lightBackground
    },
    onShowConstellations() {
      this.options.constellations = !this.options.constellations
    },
    onShowMilkyWay() {
      this.options.milkyway = !this.options.milkyway
    },
    onShowCoordinates() {
      this.options.coordinateGrid = !this.options.coordinateGrid
    },
  },
})
