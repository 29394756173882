type Ratio = '75' | '43'

interface IURLParameters {
  printMode: boolean
  posterId: string | undefined
  preview: boolean
  ratio: Ratio
  highlightDigitalFile: boolean
}

function parseURLParameters(): { [s: string]: string } {
  const parameters: { [s: string]: string } = {}
  const search = decodeURIComponent(location.search.substring(1))
  let backParams = location.href.split('#')[1]
  if (backParams) {
    backParams = backParams.split('?')[1]
  }

  search.split('&').forEach(raw => {
    const key = raw.split('=')[0]
    const value = raw.split('=')[1]
    parameters[key] = value
  })
  if (!parameters.posterId && backParams) {
    parameters.posterId = backParams.split('=')[1]
  }
  return parameters
}

function isRatio(val: string): val is Ratio {
  return val === '75' || val == '43'
}

const rawURLParameters = parseURLParameters()
const ratio = rawURLParameters.ratio ?? '75'
if (!isRatio(ratio)) {
  throw new Error('Invalid value for ratio')
}

const parameters: IURLParameters = {
  printMode: !!rawURLParameters.printMode,
  posterId: rawURLParameters.posterId || undefined,
  preview: !!rawURLParameters.preview,
  ratio,
  highlightDigitalFile: !!rawURLParameters.highlightDigitalFile
}

export default parameters
