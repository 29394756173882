import { getCookie, setCookie } from './getSetCookies'

export function getCurrencyFromCookie(): Currency | null {  
  const userCurrency = getCookie('userCurrency')
  if (userCurrency && isCurrency(userCurrency)) {
    return userCurrency
  }
  return null
}

export function storeCurrencyInCookie(currency: Currency) {
  setCookie('userCurrency', currency, 90)
}

export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
  USD = 'USD',
  CAD = 'CAD',
  NZD = 'NZD',
  SGD = 'SGD',
  AUD = 'AUD',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCurrency(maybeCurrency: any): maybeCurrency is Currency {
  return maybeCurrency in Currency
}

export const isUSLocaleCurrency: Record<Currency, boolean> = {
  AUD: true,
  CAD: true,
  CHF: false,
  EUR: false,
  NZD: true,
  SGD: true,
  USD: true,
}
