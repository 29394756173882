// This maps backend poster data to the 'poster' object format in the editor.
import { styles } from '../styles'
import { IStarPoster } from '../posterTypes'
import getTimezone from '@/provider/timezoneProvider'

/*
  we assume that the date saved is the date the user wants to have on his poster in his local timezone 
  e.g. if we wants midnight in New York, but his computer is in Germany, the date will still be midnight in Germany
  By standard, dates are saved in UTC. That leads to problems when a poster is saved and loaded in a different timezone
  e.g. client is in Germany, server has UTC time -> the date will change from midnight to 10pm when loading
  
  We therefore pretend with the following two functions that UTC time is a timezoneless local date
  -> 00:00:00+02:00 will be saved as 00:00:00Z, although it actually is 22:00:00Z. 
  saveTimeToBrowserTime will do the same in reverse
  -> 00:00:00Z will be loaded as 00:00:00+02:00, not as 02:00:00+02:00
*/
function browserTimeToSaveTime(date: Date): string {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000
  ).toISOString()
}

function saveTimeToBrowserTime(dateAsString: string | Date): Date {
  const date = new Date(dateAsString)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}

export async function mapPosterDataIncoming(poster): Promise<IStarPoster> {
  let useLightBackground = false
  let styleNameIn = poster.style
  if (poster.style.includes('OnLight')) {
    useLightBackground = true
    styleNameIn = poster.style.replace('OnLight', '')
  }
  if (poster.version < 1.3) {
    poster.posterDate = await handleLegacyDate(
      poster.version,
      poster.coords[0],
      poster.coords[1],
      new Date(poster.posterDate),
      poster.createdAt
    )
  }
  const date: Date = saveTimeToBrowserTime(poster.posterDate)
  const mappedPosterDataRecieved = {
    location: {
      locationName: poster.location,
      lat: poster.coords[0],
      long: poster.coords[1],
    },
    date,
    dateFormatted: poster.posterDateFormatted,
    displayTime: poster.displayTime,
    advancedOptions: {
      customLocation: poster.customLocation,
      customDate: poster.customDate,
      showLocation: poster.showLocation,
      showCoordinates: poster.showCoordinates,
      showDate: poster.showDate,
    },
    dedication: poster.dedication || ''.split('\n'),
    style: styles[styleNameIn],
    shape: poster.shape,
    options: {
      lightBackground: useLightBackground,
      constellations: poster.showConstellations,
      milkyway: poster.showMilkyway,
      coordinateGrid: poster.showCoordinateNet,
      bold: poster.bold || false,
    },
  }
  return mappedPosterDataRecieved
}

export function mapPosterDataOutgoing(poster: IStarPoster) {
  let styleNameOut: string
  if (poster.options.lightBackground) {
    styleNameOut = poster.style.name + 'OnLight'
  } else {
    styleNameOut = poster.style.name
  }
  const mappedPosterDataSend = {
    posterDate: browserTimeToSaveTime(poster.date),
    posterDateFormatted: poster.dateFormatted,
    location: poster.location.locationName,
    customLocation: poster.advancedOptions.customLocation,
    customDate: poster.advancedOptions.customDate,
    dedication: poster.dedication.join('\n'),
    style: styleNameOut,
    shape: poster.shape,
    showStars: true,
    showMilkyway: poster.options.milkyway,
    showCoordinateNet: poster.options.coordinateGrid,
    showConstellations: poster.options.constellations,
    showDate: poster.advancedOptions.showDate,
    showLocation: poster.advancedOptions.showLocation,
    showCoordinates: poster.advancedOptions.showCoordinates,
    displayTime: poster.displayTime || false,
    lat: poster.location.lat,
    long: poster.location.long,
    version: 1.3,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    coords: [poster.location.lat, poster.location.long],
    bold: poster.options.bold,
  }
  return mappedPosterDataSend
}

async function handleLegacyDate(
  version: number,
  lat: number,
  long: number,
  date: Date,
  createdAt: string
): Promise<Date> {
  // prior to version 1.3 we had the following bugs
  // in version 1.2 we saved posters in UTC depending on their local timezone
  // -> suppose the user was in +02:00 and design a poster for midnight. It would be saved as 22:00:00Z
  // this leads to problems when loading a poster in a different timezone (e.g. UTC time for rendering)
  // unfortunately, we do not know in which timezone the user was. Almost all of our users are in GMT,
  // therefore, we assume GMT time
  // We need to get the timezone because of daylight saving time
  const GMT = await getTimezone(52, 13, new Date(createdAt).getTime())
  date = moveTimeByOffset(date, GMT.offset)
  // prior to 1.2 we had another bug, which I can not reproduce. But when the timezone of the poster location,
  // does not equal the user timezone, everything goes to shit. We throw an error in this case
  if (version < 1.3) {
    const locationTimeZone = await getTimezone(
      lat,
      long,
      new Date(createdAt).getTime()
    )
    if (locationTimeZone.offset !== GMT.offset) {
      throw new Error('can not process legacy map. Needs to be recreated')
    }
  }
  return date
}

function moveTimeByOffset(date: Date, offset: number) {
  return new Date(date.getTime() + offset * 60 * 1000)
}
