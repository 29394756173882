import { ICelestial } from '@/math'
import drawStars from './drawStars'
import drawConstellations from './drawConstellations'
import drawMilkyWay from './drawMilkyWay'
import drawGraticule from './drawGraticule'
import drawHeart from './drawHeart'
import { IColor, Shapes } from '../../posterTypes'
import { heartPath } from './heartPath'

export default function drawCelestial(
  ctx: CanvasRenderingContext2D,
  celestial: ICelestial,
  width: number,
  style: IColor,
  shape: Shapes,
  showConstellations: boolean,
  showMilkyWay: boolean,
  showGraticule: boolean,
  drawBold: boolean
): void {
  const { stars, constellations, milkyWay, graticule } = celestial

  ctx.restore()
  ctx.clearRect(0, 0, width, width)
  ctx.save()
  if (shape === Shapes.HEART) {
    drawHeart(ctx, width)
  } else {
    ctx.beginPath()
    ctx.arc(width / 2, width / 2, width / 2, 0, Math.PI * 2)
    ctx.clip()
  }
  ctx.fillStyle = style.mapColor
  ctx.fillRect(0, 0, width, width)
  ctx.fillStyle = style.starColor
  ctx.shadowColor = style.milkywayColor

  drawStars(ctx, stars, width, shape)

  if (showConstellations) {
    drawConstellations(ctx, constellations, width, style.starColor)
  }

  if (showMilkyWay) {
    drawMilkyWay(
      ctx,
      milkyWay,
      width,
      style.milkywayColor,
      style.milkywayOpacity,
      shape
    )
  }

  if (showGraticule) {
    drawGraticule(style.starColor, ctx, graticule, width)
  }

  if (drawBold) {
    ctx.strokeStyle = style.starColor
    ctx.globalAlpha = 1
    if (shape === Shapes.HEART) {
      ctx.lineWidth = 3
      drawHeartOutline(ctx, width)
    } else {
      ctx.lineWidth = 0.005 * width
      ctx.beginPath()
      const halfWidth = width / 2
      ctx.arc(
        halfWidth,
        halfWidth,
        halfWidth - ctx.lineWidth / 2,
        0,
        Math.PI * 2
      )
      ctx.stroke()
    }
  }
}

function drawHeartOutline(ctx: CanvasRenderingContext2D, width: number) {
  ctx.setTransform(1, 0, 0, 1, 0, 0)
  ctx.beginPath()
  ctx.scale((3.96 / 1024) * width, (4.1 / 1024) * width)
  ctx.translate(1, 6)
  ctx.stroke(heartPath)
}

// This is disabled since it could negatively impact conversion rate, but we leave the code in case we need it
// function drawWatermark(ctx: CanvasRenderingContext2D, width: number,  shape: Shapes, veryBold: boolean) {
//   ctx.globalAlpha = veryBold ? 1 : 0.25
//   const word = 'PREVIEW'
//   ctx.save()
//   ctx.resetTransform()
//   ctx.rotate(-Math.PI / 4)
//   if (shape === Shapes.CIRCLE) {
//     const fontSize = Math.floor(894 / 4260 * width)
//     ctx.font = fontSize+'px Arial'
//     ctx.fillText(word, -2080 / 4260 * width, 3328 / 4260 * width)
//   }
//   else {
//     const fontSize = Math.floor(680 / 4260 * width)
//     ctx.font = fontSize+'px Arial'
//     ctx.fillText(word, -1200 / 4260 * width, 3228 / 4260 * width)
//   }
//   ctx.restore()
// }