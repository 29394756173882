import isUSLocale, { language } from '@/util/locale'
import { getCurrencyFromCookie } from '@/util/preferredCurrency'

interface IDefaultCity {
  city: string
  lat: number
  long: number
}
export default function getDefaultCity(): IDefaultCity {
  if (language === 'de') {
    return {
      city: 'Berlin',
      lat: 52.520008,
      long: 13.404954,
    }
  } else if (language === 'fr') {
    return {
      city: 'Paris',
      lat: 48.864716,
      long: 2.349014,
    }
  } else if (language === 'es') {
    return {
      city: 'Madrid',
      lat: 40.416775,
      long: -3.70379,
    }
  } else if (language === 'it') {
    return {
      city: 'Roma',
      lat: 41.902782,
      long: 12.496366,
    }
  } else if (language === 'en' && getCurrencyFromCookie() === 'CAD') {
    return {
      city: 'Toronto',
      lat: 43.70011,
      long: -79.4163,
    }
  } else if (language === 'en' && getCurrencyFromCookie() === 'AUD') {
    return {
      city: 'Sydney',
      lat: -33.8688197,
      long: 151.2092955,
    }
  } else if (language === 'en' && getCurrencyFromCookie() === 'NZD') {
    return {
      city: 'Wellington',
      lat: -41.2923814,
      long: 174.7787463,
    }
  } else if (language === 'en' && getCurrencyFromCookie() === 'SGD') {
    return {
      city: 'Singapore',
      lat: 1.352083,
      long: 103.819836,
    }
  } else if (language === 'en' && isUSLocale()) {
    return {
      city: 'New York City',
      lat: 40.712812,
      long: -74.006062,
    }
  } else if (language === 'en') {
    return {
      city: 'Copenhagen',
      lat: 55.676098,
      long: 12.568337,
    }
  } else if (language === 'nl') {
    return {
      city: 'Amsterdam',
      lat: 52.377956,
      long: 4.89707,
    }
  } else {
    throw new Error('Could not find language ' + language)
  }
}