import { PrintType } from '@/types/IPrintInfo'

export type VideoPrint =
  | 'poster'
  | 'poster-framed'
  | 'gallery-print'
  | 'gallery-us'
  | 'metal'
  | 'poster-framed-us'
export type Entry = {
  imgPath: string
  imgHash: string
  translationKey: string
}

interface IVideoSource {
  src: string
  res: number
  autoplay: true
}
export interface IBackgroundVideoSource {
  mobile: IVideoSource[]
  desktop: IVideoSource[]
}

export const printImages: Record<VideoPrint, Entry[]> = {
  metal: [
    {
      imgPath: '/images/productPictures/metal/corner',
      imgHash: 'f3cfde82',
      translationKey: 'metalDescription',
    },
    {
      imgPath: '/images/productPictures/detail/black_celestial',
      imgHash: '2c0a29d0',
      translationKey: 'satinPrint',
    },
    {
      imgPath: '/images/productPictures/metal/detail',
      imgHash: '2f2a557d',
      translationKey: 'sharpDetails',
    },
    {
      imgPath: '/images/productPictures/metal/back',
      imgHash: '6ed552f9',
      translationKey: 'hangingIncluded',
    },
  ],
  poster: [
    {
      imgPath: '/images/productPictures/poster/black_white',
      imgHash: '92cf505d',
      translationKey: 'posterDescription',
    },
    {
      imgPath: '/images/productPictures/detail/black_celestial',
      imgHash: '2c0a29d0',
      translationKey: 'satinPrint',
    },
    {
      imgPath: '/images/productPictures/detail/heart_dedication',
      imgHash: 'e258f736',
      translationKey: 'sharpDetails',
    },
    {
      imgPath: '/images/productPictures/poster/darkblue_darkblue',
      imgHash: 'b10cd5fb',
      translationKey: 'amazingColors',
    },
  ],
  'gallery-print': [
    {
      imgPath: '/images/productPictures/gallery/above',
      imgHash: 'f0c1cc95',
      translationKey: 'galleryDescription',
    },
    {
      imgPath: '/images/productPictures/gallery/corner_zoom',
      imgHash: 'e2a23c6f',
      translationKey: 'galleryMoreDescription',
    },
    {
      imgPath: '/images/productPictures/gallery/front',
      imgHash: '1b18339c',
      translationKey: 'sharpDetails',
    },
    {
      imgPath: '/images/productPictures/gallery/corner_far',
      imgHash: 'a0dedc30',
      translationKey: 'framelessEdges',
    },
    {
      imgPath: '/images/productPictures/gallery/back',
      imgHash: 'f15e4463',
      translationKey: 'hangingIncluded',
    },
  ],
  'gallery-us': [
    {
      imgPath: '/images/productPictures/gallery_us/front',
      imgHash: '9c1d6f8c',
      translationKey: 'galleryDescriptionUS',
    },
    {
      imgPath: '/images/productPictures/gallery_us/corner',
      imgHash: '40724c7d',
      translationKey: 'framelessEdges',
    },
    {
      imgPath: '/images/productPictures/detail/heart_dedication',
      imgHash: 'e258f736',
      translationKey: 'sharpDetails',
    },
    {
      imgPath: '/images/productPictures/gallery_us/backside',
      imgHash: '3c6313ec',
      translationKey: 'hangingIncluded',
    },
  ],
  'poster-framed': [
    {
      imgPath: '/images/productPictures/framed/black_frame',
      imgHash: '5d29505b',
      translationKey: 'frameDescription',
    },
    {
      imgPath: '/images/productPictures/framed/corner',
      imgHash: 'a90a466b',
      translationKey: 'frameCorner',
    },
    {
      imgPath: '/images/productPictures/detail/black_celestial',
      imgHash: '2c0a29d0',
      translationKey: 'satinPrint',
    },
    {
      imgPath: '/images/productPictures/framed/wood_frame',
      imgHash: 'd5c16b51',
      translationKey: 'frameWoodDescription',
    },
    {
      imgPath: '/images/productPictures/framed/white_frame',
      imgHash: 'b6668daf',
      translationKey: 'frameWhiteDescription',
    },
    {
      imgPath: '/images/productPictures/detail/heart_dedication',
      imgHash: 'e258f736',
      translationKey: 'sharpDetails',
    },
    {
      imgPath: '/images/productPictures/framed/backside',
      imgHash: '270d6311',
      translationKey: 'hangingIncluded',
    },
  ],
  'poster-framed-us': [
    {
      imgPath: '/images/productPictures/framed/black_frame',
      imgHash: '5d29505b',
      translationKey: 'frameDescriptionUS',
    },
    {
      imgPath: '/images/productPictures/framed/corner',
      imgHash: 'a90a466b',
      translationKey: 'frameCorner', 
    },
    {
      imgPath: '/images/productPictures/detail/black_celestial',
      imgHash: '2c0a29d0',
      translationKey: 'satinPrint',
    },
    {
      imgPath: '/images/productPictures/framed/wood_frame',
      imgHash: 'd5c16b51',
      translationKey: 'frameWoodDescription',
    },
    {
      imgPath: '/images/productPictures/framed/white_frame',
      imgHash: 'b6668daf',
      translationKey: 'frameWhiteDescription',
    },
    {
      imgPath: '/images/productPictures/detail/heart_dedication',
      imgHash: 'e258f736',
      translationKey: 'sharpDetails',
    },
    {
      imgPath: '/images/productPictures/framed/backside',
      imgHash: '270d6311',
      translationKey: 'hangingIncluded',
    },
  ],
}

export const sources: Record<VideoPrint, IBackgroundVideoSource> = {
  poster: makeSource(PrintType.poster),
  metal: makeSource('metal'),
  'gallery-us': makeSource('gallery-us'),
  'gallery-print': makeSource(PrintType.galleryPrint),
  'poster-framed': makeSource(PrintType.posterFramed),
  'poster-framed-us': makeSource(PrintType.posterFramed)
}

function makeVideoSource(
  print: VideoPrint,
  alignment: 'square' | 'horizontal',
  res: number
): IVideoSource {
  return {
    src: `/assets/video/sized/${print}.${alignment}.${res}.mp4`,
    autoplay: true,
    res,
  }
}

function makeSource(print: VideoPrint): IBackgroundVideoSource {
  return {
    desktop: [360, 480, 720].map(res =>
      makeVideoSource(print, 'horizontal', res)
    ),
    mobile: [360, 480, 720].map(res => makeVideoSource(print, 'square', res)),
  }
}

export function printToVideoPrint(
  print: Exclude<PrintType, 'digital'>,
  usVersion: boolean
): VideoPrint {
  if (print === PrintType.galleryPrint && usVersion) {
    return 'gallery-us'
  }
  if (print === PrintType.posterFramed && usVersion) {
    return 'poster-framed-us'
  }
  if (print === PrintType.metalPrint) {
    return 'metal'
  }
  return print
}
