


















































import Vue from 'vue'
import '../../assets/css/inputs.scss'
import { IColor, Shapes } from '@/posterTypes'
import { styles } from '../../styles'
import Checkbox from './atomics/Checkbox.vue'
export default Vue.extend({
  components: { Checkbox },
  name: 'Theme',
  model: {
    prop: 'theme',
    event: 'change',
  },
  props: {
    shape: {
      type: String as () => Shapes,
      required: true,
    },
    lightBackground: {
      type: Boolean,
      required: true,
    },
    bold: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    theme: styles,
  }),
  computed: {
    currentTheme(): string {
      return this.theme.name
    },
    themes(): IColor[] {
      if (this.isMobile) {
        return Object.values(styles).filter(s => !s.hiddenOnMobile)
      }
      return Object.values(styles)
    },
  },
  methods: {
    onStyleChange(event): void {
      const target = event.target as HTMLTextAreaElement
      this.$emit('change', this.themes[target.id])
    },
    changeShape() {
      if (this.shape === Shapes.CIRCLE) {
        this.$emit('update:shape', Shapes.HEART)
      } else {
        this.$emit('update:shape', Shapes.CIRCLE)
      }
    },
    changeBold() {
      this.$emit('update:bold', !this.bold)
    },
    changeBackground() {
      this.$emit('update:lightBackground', !this.lightBackground)
    },
  },
})
