import VueI18n from 'vue-i18n'
import { backendUrl } from './util/endpointConfig'
import { language } from './util/locale'

export const translationLoaded: { loaded: boolean } = {
  loaded: false,
}

export function init(): VueI18n {
  const i18n = new VueI18n({
    locale: language, // set locale
    fallbackLocale: 'en',
    messages: {},
  })
  loadLanguage(language, i18n).then(() => (translationLoaded.loaded = true))
  if (language !== 'en') {
    loadLanguage('en', i18n)
  }
  return i18n
}

async function fetchTranslations(
  url: string,
  prefix?: string
): Promise<Record<string, string>> {
  const request = await fetch(url)
  const messages = (await request.json()) as Record<string, string>
  if (!prefix) {
    return messages
  } else {
    const result = {}
    Object.keys(messages).forEach(k => {
      result[prefix + ':' + k] = messages[k]
    })
    return result
  }
}

async function loadLanguage(lang: string, i18n: VueI18n) {
  const messages = await Promise.all([
    fetchTranslations(backendUrl + `translations/${lang}/starmap.json`),
    //fetchTranslations(backendUrl + `translations/${lang}/editor.json`, 'editor')
  ])
  const [allTranslations, ...otherTranslations] = messages
  for (const translations of otherTranslations) {
    for (const key of Object.keys(translations)) {
      allTranslations[key] = translations[key]
    }
  }
  i18n.setLocaleMessage(lang, allTranslations)
}
