import { ICelestial, ICelestialPos } from '@/math'

import Easers from './Easers'
import getCelestialAsPromise from './webworker/getCelestialAsPromise'
import interpolateCelestialPos from './interpolateCelestialPos'
import parameters from '../../../util/urlParameters'

const ANIMATION_DURATION = 2000
const TARGET_FRAME_RATE = 30
const FRAMES_PER_ANIMATION = (ANIMATION_DURATION / 1000) * TARGET_FRAME_RATE
const MS_PER_FRAME = 1000 / TARGET_FRAME_RATE

const displayAnimation = !parameters.printMode
if (!displayAnimation) {
  console.log('do not animate')
}

export interface IWebWorker {
  // eslint-disable-next-line
  run: any
}

export interface ICelestialPosWithAnimationPortion extends ICelestialPos {
  animationPortion: number
}

export function Animator(startPos: ICelestialPos, endPos: ICelestialPos) {
  // start time of the animation
  let animationStart
  // most recent position that was displayed on the celestial
  let currentPos: ICelestialPos = startPos
  // boolean indicating if a celestial has been computed before (if not we delay the animation start)
  let firstCelestialComputed = false
  // computed celestials from the webworker
  let celestials: ICelestial[] = []
  // indicates the how manyth animation this is (used for determining if an animation became stale)
  let animationNumber = 0
  // total time spent by the web worker actually calculating things
  let busyTime = 0
  const pendingPromises: ((celestial: ICelestial) => void)[] = []
  async function changeAnimationTarget(
    startPos: ICelestialPos,
    endPos: ICelestialPos
  ) {
    const localAnimationNumber = ++animationNumber
    firstCelestialComputed = false
    animationStart = Date.now()
    celestials = []
    busyTime = 0

    if (!displayAnimation) {
      await computeCelestial({
        ...endPos,
        animationPortion: 1,
      })
      return
    }
    // this should not always be needed, only if we draw for the first time
    await computeCelestial({
      ...startPos,
      animationPortion: 0,
    })
    let lastAnimationPortion = 0
    while (localAnimationNumber === animationNumber) {
      const targetPortion = Math.min(
        Math.max(lastAnimationPortion, currentAnimationPortion()) +
          1 / FRAMES_PER_ANIMATION,
        1
      )
      // if we are 5 frames ahead in the calculation, we skip calculating for 1 frame
      if (
        targetPortion >
        currentAnimationPortion() + 5 / FRAMES_PER_ANIMATION
      ) {
        await new Promise(res => setTimeout(res, MS_PER_FRAME))
      }

      lastAnimationPortion = targetPortion
      const easer =
        animationNumber === 1 ? Easers.easeOutQuint : Easers.easeInOutQuint
      const position = interpolateCelestialPos(
        startPos,
        endPos,
        easer(targetPortion),
        targetPortion
      )
      await computeCelestial(position)

      if (targetPortion === 1) {
        console.log('busytime', busyTime)
        break
      }
    }
  }
  changeAnimationTarget(startPos, endPos)
  // lets the webworker compute a celestial
  async function computeCelestial(pos: ICelestialPosWithAnimationPortion) {
    try {
      const celestial = await getCelestialAsPromise(pos)
      if (!firstCelestialComputed) {
        firstCelestialComputed = true
        animationStart = Date.now()
      }
      busyTime += celestial.meta.computedIn
      if (celestial.meta.animationPortion === undefined) {
        throw new Error('Celestial must have animationPortion')
      }

      const pendingPromise = pendingPromises.shift()
      if (pendingPromise) {
        pendingPromise(celestial)
      } else {
        celestials.push(celestial)
      }
    } catch (e) {
      console.error(e)
      console.log('computation dropped')
    }
  }

  // the animation portion is a value between 0 and 1
  function currentAnimationPortion(): number {
    if (!firstCelestialComputed) {
      return 0
    }
    if (!displayAnimation) {
      return 1
    }
    return Math.min((Date.now() - animationStart) / ANIMATION_DURATION, 1)
  }

  async function getCelestial(): Promise<ICelestial> {
    const result = celestials.shift()
    if (result) {
      return result
    }
    return new Promise(res => {
      pendingPromises.push(res)
    })
  }

  return {
    changeLocation(endPos: ICelestialPos): void {
      changeAnimationTarget(currentPos, endPos)
    },
    async next(): Promise<ICelestial> {
      const celestial = await getCelestial()
      currentPos = celestial.position
      return celestial
    },
    last(): ICelestial | undefined {
      if (currentAnimationPortion() !== 1) {
        throw new Error(
          'animation still running. Can not compute last celestial'
        )
      }
      return celestials.pop()
    },
    isAnimationRunning(): boolean {
      return currentAnimationPortion() !== 1
    },
  }
}
