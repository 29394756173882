





























import Vue from 'vue'
import '../../assets/css/inputs.scss'
import { shortenLines, reduceLineNumbers } from '../../util/inputTools'
import { isiOS } from '@/util/isSafari'

export default Vue.extend({
  name: 'Dedication',
  model: {
    prop: 'dedication',
    event: 'change',
  },
  props: {
    dedication: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    style: '',
    error: undefined,
    isFocused: false,
  }),
  methods: {
    handleClick() {
      if (this.isMobile && !isiOS) {
        this.isFocused = true
      }
    },
    revertInputStyle() {
      this.isFocused = false
    },
    onDedicationChange(event): void {
      const input = (event.target as HTMLTextAreaElement).value
      const lines = input.split('\n')

      const wasChanged = (newLines: string[]) =>
        newLines.join('\n') !== lines.join('\n')

      this.error = undefined
      const linesWithReducedCount = reduceLineNumbers(lines)
      if (wasChanged(linesWithReducedCount)) {
        this.error = 'tooManyLines'
      }
      const shortenedLines = shortenLines(lines)
      if (wasChanged(shortenedLines)) {
        this.error = 'lineExceeded'
      }
      this.$emit('error', this.error ? 'visible' : 'none')
      this.$emit('change', lines)
    },
  },
})
