












import Vue from 'vue'
import '../../../assets/css/inputs.scss'
export default Vue.extend({
  name: 'ThemeToggle',
  model: {
    prop: 'value',
    event: 'boolean',
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  methods: {},
})
