










































import Vue from 'vue'
import Item from './Item.vue'
import PrintItem from '../Print.vue'
import { IDBSettings } from '../../../types/basicTypes'
import { IPrintInfo, PrintType } from '../../../types/IPrintInfo'
import { IDisplayProps } from '../../../types/IDisplayProps'

const hoverChangePrintTimeout = 300

export default Vue.extend({
  name: 'Picker',
  props: {
    prints: {
      type: Array as () => IPrintInfo[],
      required: true,
    },
    print: {
      type: String as () => PrintType,
      required: true,
    },
    dbSettings: {
      type: Object as () => IDBSettings,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    displayProps: {
      type: Object as () => IDisplayProps,
      required: true,
    },
  },
  model: {
    prop: 'print',
    event: 'change',
  },
  data() {
    return {
      dragXstart: 0, // position where the user started dragging
      draggedCarouselPosition: undefined, // Position of the carousel when the user drags it
      printIdAtDragStart: undefined, // the print that was selected when the user started dragging
      expandedItem: undefined, // item that is currently expanded === more text visible
      hoverTimeout: undefined, // timeout that causes the print to change when user hovers over it
      centerId: this.prints.findIndex(p => p.id === this.print) // id of the initial print, will receive an xOffset of 0
    }
  },
  computed: {
    navLeft() {
      return {
        display: this.currentFocus === 0 ? 'none' : null,
      }
    },
    navRight() {
      return {
        display: this.currentFocus + 1 >= this.prints.length ? 'none' : null,
      }
    },
    nonDraggedCarouselPosition() {
      // Position of the carousel based on the selected print
      // item width is undefined in the beginning, so we take this shortcut
      if (this.currentFocus == this.centerId) {
        return 0
      }
      return (this.currentFocus - this.centerId) * this.itemWidth()
    },
    xOffset() {
      if (!this.isMobile) {
        return {}
      }
      const pos =
        this.draggedCarouselPosition ?? this.nonDraggedCarouselPosition
      return {
        transform: `translateX(${-pos}px)`,
      }
    },
    currentFocus() {
      return this.prints.findIndex(p => p.id === this.print)
    },
    canDrag(): boolean {
      return this.expandedItem === undefined
    },
  },
  methods: {
    relativePositionChange(direction: number): void {
      this.absolutePositionChange(this.currentFocus + direction)
    },
    absolutePositionChange(newPosition: number): void {
      if (newPosition < 0 || newPosition > this.prints.length) {
        return
      }
      const newPrint = this.prints[newPosition].id
      if (newPrint === this.print) {
        return
      }
      this.$emit('change', newPrint)
      if (this.isMobile) {
        this.expandedItem = undefined
      }
    },
    dragStart(e) {
      e = e || window.event
      const el = document.querySelector('#draggableEl') as HTMLElement
      const posInitial = el.getBoundingClientRect().left
      this.printIdAtDragStart = this.currentFocus

      if (e.type == 'touchstart') {
        this.dragXstart = e.touches[0].clientX - posInitial
      } else {
        this.dragXstart = e.clientX - posInitial
        document.onmouseup = this.dragEnd
        document.onmousemove = this.dragAction
      }
    },
    dragAction(e): void {
      if (!this.canDrag) {
        return
      }
      e = e || window.event
      const dragXcurrent =
        e.type === 'touchmove' ? e.touches[0].clientX : e.clientX
      this.draggedCarouselPosition = this.dragXstart - dragXcurrent
      // prevents the users from dragged away from the items at the border
      // 0.2 enables the user to drag a bit further
      const lowerBound = -(0.2 + this.centerId) * this.itemWidth()
      const upperBound = (0.2 + this.prints.length - this.centerId - 1) * this.itemWidth()

      this.draggedCarouselPosition = Math.max(
        Math.min(this.draggedCarouselPosition, upperBound),
        lowerBound
      )

      // determine which item the user selected by dragging
      const itemBoundary = this.itemWidth() / 2
      // we want to make it easier for the user to swipe to the next entry, so we use this
      // it would be even coolor to use his swiping velocity to calculate a likely stop,
      // but thats overkill for now
      const dragAdjustedBoundary = 0.5 * itemBoundary

      const startPos = (this.printIdAtDragStart - this.centerId) * this.itemWidth()
      const movedAmount = this.draggedCarouselPosition - startPos
      if (movedAmount > dragAdjustedBoundary) {
        this.absolutePositionChange(this.printIdAtDragStart + 1)
      } else if (movedAmount < -dragAdjustedBoundary) {
        this.absolutePositionChange(this.printIdAtDragStart - 1)
      } else {
        this.absolutePositionChange(this.printIdAtDragStart)
      }
    },
    dragEnd() {
      this.dragXstart = undefined
      this.dragXcurrent = undefined
      this.draggedCarouselPosition = undefined
      document.onmouseup = null
      document.onmousemove = null
    },
    itemWidth(): number {
      // we store the item width in a variable
      if (!this._itemWidth) {
        // needs to be changed if css is updated
        const distanceBetweenItems = 1.05
        const element = document.querySelector('#draggableEl .item')
        // edge case if the value is requested too early
        // that means that we currently have to show the middle item initially
        if (!element) {
          return 0
        }
        this._itemWidth =
          element.getBoundingClientRect().width * distanceBetweenItems
      }
      return this._itemWidth
    },
    toggleExpand(print: PrintType) {
      if (this.expandedItem === print) {
        this.expandedItem = undefined
      } else {
        this.expandedItem = print
      }
    },
    onMouseEnter(newPosition: number) {
      clearTimeout(this.hoverTimeout)
      this.hoverTimeout = setTimeout(() => {
        this.absolutePositionChange(newPosition)
      }, hoverChangePrintTimeout)
    },
    onMouseLeave() {
      clearTimeout(this.hoverTimeout)
    },
  },
  components: {
    Item,
    PrintItem,
  },
})
