































import Picker from './carouselPicker/Picker.vue'
import Vue from 'vue'
import '../../assets/css/inputs.scss'
import getAvailablePrints from '../../provider/getAvailablePrints'
import { IDBSettings } from '../../types/basicTypes'
import { getCurrency } from '../../provider/getCurrency'
import PrintItem from './Print.vue'
import { IDisplayProps } from '../../types/IDisplayProps'
import getAddToCartUrl from '../../util/getAddToCartUrl'
import { PrintType } from '../../types/IPrintInfo'
import parameters from '@/util/urlParameters'
import { defaultPrint } from '@/provider/makeEditorData'
import * as Sentry from '@sentry/browser'

export default Vue.extend({
  name: 'PrintSelect',
  props: {
    dbSettings: {
      type: Object as () => IDBSettings,
      required: false,
    },
    displayProps: {
      type: Object as () => IDisplayProps,
      required: true,
    },
  },
  data() {
    return {
      availablePrints: [],
      currency: undefined,
    }
  },
  async mounted() {
    this.availablePrints = await getAvailablePrints()
    const framedPoster = this.availablePrints.find(
      p => p.id === 'poster-framed'
    )
    // select another default frame color, if black is unavailable
    if (
      framedPoster &&
      !framedPoster.availableColors.includes(this.displayProps.frameColor)
    ) {
      this.displayProps.frameColor = framedPoster.availableColors[0]
    }
    const currency = await getCurrency()
    const defaultCurrency = this.availablePrints[0].defaultCurrency
    this.currency = currency || defaultCurrency

    Sentry.setContext(
      'DEBUG: NoPriceForPrintType - PrintSelect.vue - mounted()',
      {
        availablePrintsCurrency: defaultCurrency,
        currency,
      }
    )
  },
  computed: {
    mainPrints() {
      return this.galleryPrintAvailable ? this.availablePrints.filter(
        p => p.id !== 'poster-framed_30x40cm'
      ) : this.availablePrints;
    },
    galleryPrintAvailable() {
      return this.availablePrints.some(({id}) => id === 'gallery-print');
    },
    digitalFileAvailable() {
      return this.availablePrints.some(({id}) => id === 'digital');
    },
    // on desktop, we show the digital file as a separate product
    // unless a certain URL parameter is present
    // this is needed for compliance reasons with Google Shopping
    showSeparateDigitalProduct() {
      if (parameters.highlightDigitalFile) {
        return false;
      }
      if (!this.digitalFileAvailable) {
        return false;
      }
      return (this.mainPrints.length === 6 || !this.isMobile);
    },
    carouselPrints() {
      // still loading
      if (this.mainPrints.length == 0) {
        return this.mainPrints
      }
      if (this.mainPrints.length > 6 || this.mainPrints.length < 2) {
        throw new Error(
          'Number of prints out of bounds for UI: ' + this.mainPrints.length
        )
      }
      if (this.mainPrints.length <= 3) {
        return this.mainPrints
      }
      if (this.showSeparateDigitalProduct) {
        return this.mainPrints.filter(p => p.id !== 'digital')
      } else {
        return this.mainPrints
      }
    },
    digitalProduct() {
      if (!this.showSeparateDigitalProduct) {
        return undefined
      }
      return this.mainPrints.find(p => p.id === 'digital')
    },
    framedPosterProduct() {
      return this.availablePrints.find(p => p.id === 'poster-framed')
    },
    loaded() {
      return this.currency && this.availablePrints
    },
    print() {
      return this.displayProps.print
    },
    containerStyle() {
      return this.shouldShow('/digital') || this.shouldShow('/frame-select')
        ? {
            width: '100%',
          }
        : null
    },
  },
  methods: {
    shouldShow(route) {
      return this.$route.path.startsWith(route)
    },
    setPrint(newVal) {
      // prevents an edge-case when the user is immediately visiting the products page
      if (!newVal) {
        return
      }
      this.displayProps.print = newVal
    },
    setFrameColor(newVal) {
      this.displayProps.frameColor = newVal
    },
  },
  watch: {
    $route: {
      handler(newLocation: { path: string }, oldLocation?: { path: string }) {
        const isVisibleChecker = path =>
          path.startsWith('/products') ||
          path === '/digital' ||
          path === '/frame-select'
        const componentIsVisible = isVisibleChecker(newLocation.path)
        this.displayProps.show3d = componentIsVisible
        if (!this.loaded) {
          return
        }
        // set initial print to default print, or if digital file was visible before
        if (newLocation.path === '/products' && oldLocation?.path === '/digital') {
          this.setPrint(defaultPrint)
        }
        if (newLocation.path === '/digital') {
          this.setPrint(this.digitalProduct.id)
        }
        if (newLocation.path === '/frame-select') {
          if (this.framedPosterProduct) {
            this.setPrint(this.framedPosterProduct.id)
          } else {
            // if no frames are available we work around the routing to the frame select this way
            // maybe refactor this later
            const url = getAddToCartUrl(
              PrintType.poster,
              this.dbSettings.currentPosterId ||
                localStorage.getItem('currentPosterId'),
              this.dbSettings.initialPosterId
            )
            if (!url) {
              throw new Error('Url was undefined. This may not happen')
            }
            location.replace(url)
          }
        }
      },
      immediate: true,
    },
  },
  components: {
    Picker,
    PrintItem,
  },
})
