









import Vue from 'vue'

export default Vue.extend({
  name: 'Item',
  props: {
    offset: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFeatured() {
      return this.type === 'metal-print'
    },
    styleObject(): Record<string, string> {
      if (window.innerWidth <= 999) {
        const x = (this.offset) * 105
        return {
          transform: `translateX(${x}%)`,
        }
      }
      return {}
    },
  },
  methods: {},
  components: {},
})
