import dayjs from 'dayjs'
const userLang = navigator.language
const MAX_YEARS = dayjs().year() + 5
const MIN_YEARS = 1900

const getYears = (): number[] => {
  const years: number[] = []
  let i = MAX_YEARS
  while (i > MIN_YEARS) {
    years.push(i)
    i--
  }
  return years
}

const getMonths = (): string[] => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
}

const formatDate = (date: Date, withTime: boolean): string => {
  /**
   * What the hell happens here?
   *
   * Safari has a bug that some old dates will not display the localeDateString in the timezone
   * of the date but in the current time zone. That is a problem with daylight saving time
   *
   * Example input: March 31 1969 00:00 becomes March 30 1969 23:00 if we are currently in DST
   *
   * Fixing this is hard, but toLocaleDateString allows UTC times. Therefore I add the timezone
   * offset and then treat the date as a UTC date.
   */
  const fakeUTCDate = dayjs(date)
    .subtract(date.getTimezoneOffset(), 'minute')
    .toDate()
  // the docs say that when you pass undefined to "toLocaleDateString" it should use the default language. However mine (Daniel's) Firefox and Chrome do not do that, therefore I pass the language
  const localizedDate = fakeUTCDate.toLocaleDateString(userLang, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  })

  const localizedTime = fakeUTCDate.toLocaleTimeString(userLang, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  })
  return withTime ? localizedDate + ', ' + localizedTime : localizedDate
}

export { getYears, getMonths, formatDate }
