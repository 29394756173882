import {
  Currency,
  getCurrencyFromCookie,
  isUSLocaleCurrency,
  storeCurrencyInCookie,
} from './preferredCurrency'

export type InterfaceLanguage = 'de' | 'en' | 'fr' | 'es' | 'it' | 'nl'

export enum Locale {
  enUS = 'en-US',
  de = 'de',
  fr = 'fr',
  es = 'es',
  it = 'it',
  nl = 'nl',
}

// DEV ONLY
if ('webpackHotUpdate' in window) {
  const html = document.querySelector('html')
  if (!html) {
    throw new Error('No html element')
  }
  if (!html.getAttribute('data-locale')) {
    const locale =
      prompt('Pick a locale (en-US, it, es, fr, nl, de)', 'en-US') || 'de'
    html.setAttribute('data-locale', locale)
    html.setAttribute('lang', locale.split('-')[0])
    if (locale === 'en-US') {
      storeCurrencyInCookie(Currency.USD)
    } else {
      storeCurrencyInCookie(Currency.EUR)
    }
  }
}

const languageOrUndefined:
  | InterfaceLanguage
  | undefined = document.querySelector('html')?.getAttribute('lang') as
  | InterfaceLanguage
  | undefined
const localeOrUndefined: Locale | undefined = document
  .querySelector('html')
  ?.getAttribute('data-locale') as Locale | undefined

if (!localeOrUndefined) {
  throw new Error('html attribute data-locale is missing')
}
if (!languageOrUndefined) {
  throw new Error('html attribute lang is missing')
}

// redeclaration is necessary to transform the type InterfaceLanguage | undefined to InterfaceLanguage
const locale = localeOrUndefined
const language = languageOrUndefined

export default function isUSLocale(): boolean {
  const userCurrency = getCurrencyFromCookie()
  if (locale !== Locale.enUS) {
    return false
  }
  if (userCurrency) {
    return isUSLocaleCurrency[userCurrency]
  }
  // fallback if no preferred currency was set, otherwise we would need to query the backend here
  return true
}

export { language, locale, isUSLocale }
