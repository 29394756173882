

























































import Vue from 'vue'
import '../../assets/css/inputs.scss'
import { IAdvancedOptions } from '../../posterTypes'
import Checkbox from './atomics/Checkbox.vue'

export default Vue.extend({
  name: 'AdvancedOptions',
  props: {
    advancedOptions: {
      type: Object as () => IAdvancedOptions,
      required: true,
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    customLocationStyle(): string | null {
      return this.advancedOptions.customLocation.length === 40
        ? 'border: 1px solid red'
        : null
    },
    customDateStyle(): string | null {
      return this.advancedOptions.customDate.length === 40
        ? 'border: 1px solid red'
        : null
    },
  },
  methods: {
    onCustomLocationChange(event): void {
      const target = event.target as HTMLTextAreaElement
      this.advancedOptions.customLocation = target.value
    },
    onCustomDateChange(event): void {
      const target = event.target as HTMLTextAreaElement
      this.advancedOptions.customDate = target.value
    },
    onShowLocationToggle() {
      this.advancedOptions.showLocation = !this.advancedOptions.showLocation
    },
    onShowCoordinatesToggle() {
      this.advancedOptions.showCoordinates = !this.advancedOptions
        .showCoordinates
    },
    onShowDateToggle() {
      this.advancedOptions.showDate = !this.advancedOptions.showDate
    },
    onOptionsExpand() {
      this.expanded = !this.expanded
    },
  },
  components: {
    Checkbox,
  },
})
