

























import Vue from 'vue'
import '../../assets/css/inputs.scss'
import { FrameColor } from '../../types/basicTypes'
export default Vue.extend({
  name: 'PrintFrameSelect',
  model: {
    prop: 'frameColor',
    event: 'change',
  },
  props: {
    frameColor: {
      type: String as () => FrameColor,
      required: false,
    },
    frameColors: {
      type: Array as () => FrameColor[],
      required: true,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {},
})
