












import Vue from 'vue'

export default Vue.extend({
  name: 'Box',
  props: {
    type: {
      // glass or metal
      type: String,
      required: true,
    },
  },
})
