const mediaQuery = window.matchMedia('only screen and (max-width: 999px)')

const isMobile = {
  isMobile: mediaQuery.matches,
}

mediaQuery.onchange = ev => {
  isMobile.isMobile = ev.matches
}

window.matchMedia('only screen and (max-width: 999px)').onchange
export default isMobile
