








import Vue from 'vue'

export default Vue.extend({
  name: 'PrintPhotoModal',
  props: {
    path: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sizes: [350, 600, 850, 1100, 1350, 1600, 1850, 2000],
    }
  },
  methods: {
    srcSet(fileType: 'jpg' | 'webp'): string {
      const pathPrefix =
        'webpackHotUpdate' in window
          ? 'https://deploy-preview-218--thestarposter.netlify.app'
          : ''
      const path = pathPrefix + this.path
      return this.sizes
        .map(size => [path, size, this.hash, fileType].join('.') + ` ${size}w`)
        .join(',\n')
    },
  },
})
