import { IStarPoster, Shapes } from '../posterTypes'
import formatDate from '@/util/formatDate'
import { styles } from '../styles'
import getDefaultCity from './getDefaultCity'
const location = getDefaultCity()
import dayjs from 'dayjs'
import isUSLocale from '@/util/locale'

export default function defaultPoster(): IStarPoster {
  const date = dayjs()
    .startOf('day')
    .toDate()
  return {
    location: {
      locationName: location.city,
      lat: location.lat,
      long: location.long,
    },
    date,
    dateFormatted: formatDate(date),
    displayTime: undefined,
    advancedOptions: {
      customLocation: '',
      customDate: '',
      showLocation: true,
      showCoordinates: true,
      showDate: true,
    },
    dedication: [],
    style: styles.black,
    shape: Shapes.CIRCLE,
    options: {
      lightBackground: !isUSLocale(),
      constellations: true,
      milkyway: false,
      coordinateGrid: false,
      bold: true,
    },
  }
}
